import React, { Component } from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import MovLink from "../components/movlink";
// import { FaRegClock } from "react-icons/fa";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import Layout from "../layouts";
import { Helmet } from "react-helmet";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
// import { rhythm } from "../utils/typography";

import { ShowGrid } from "../components/pageLayout/ShowGrid";
import { ShowYearLinks } from "../components/pageLayout/ShowYearLinks";
// import Vimeo from "@u-wave/react-vimeo";
import ReactPlayer from "react-player";

import {
  GridHeader,
  GridBoxContainer,
  PageHeader,
  PageSpacing,
  PaddedMobile,
} from "../utils/styles";

export const videoStyle = css`
  div {
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
  }

  div iframe,
  div object,
  div embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const ShowGridBox = css`
  overflow: hidden;
  margin: 0 auto;
  max-width: 960px;
  background: #efefef;
  a > div {
    height: 110px;
    @media (min-width: 40em) {
      height: 150px;
    }
    @media (min-width: 52em) {
      height: 130px;
    }
    @media (min-width: 64em) {
      height: 180px;
    }
  }
  h3 {
    margin-bottom: auto;
  }
  a {
    text-decoration: none;
  }
`;

class Students extends Component {
  render() {
    const data = this.props.data;

    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | Students`}
          meta={[{ name: "description", content: "students" }]}
        />
        <Flex
          mx={[-1, -1, -2]}
          flexWrap="wrap"
          css={[PageSpacing, PaddedMobile]}
        >
          <Box width={1} px={[1, 1, 2]} pb={4}>
            <h1 ref={this.myDivToFocus} css={PageHeader}>
              MA:UX Graduate Show 2022
            </h1>
          </Box>
          <Box width={[1, 2 / 3, 3 / 5]} px={[1, 1, 2]} pr={2} pb={4}>
            <p>
              “It is precisely where the reach of the imagination meets the
              friction of materials, or where the forces of ambition rub up
              against the rough edges of the world, that human life is lived”
              Tim Ingold Making, 2013
            </p>
            <p>
              MA User Experience Design students work at the interface of the
              imagination and the material implied by Ingold’s quote above.
              Their work ranges across the political and ethical commitments of
              technology, the nature of knowledge and learning and our embodied
              experience with time and sound and breath. Project work is
              undertaken in a spirit of open and collaborative inquiry, informed
              by research and with the aim of materialising experience.
            </p>
            <p>
              We emphasise design approaches that reflect the entangled nature
              of human experience, and an embodied relation with and between
              systems and objects. We call for contextual depth, material
              perception, and collaborative generosity. We are committed to
              sensitising design to social need, and involving the people who
              will be affected by a design in its production.
            </p>
          </Box>
          <Box width={[1, 1 / 3, 2 / 5]} px={[1, 1, 2]} pb={4}>
            <ShowYearLinks />
          </Box>
          <ShowGrid students={data.allWordpressWpStudent.edges} />
        </Flex>
      </Layout>
    );
  }
}

export default Students;

// Set here the ID of the home page.
export const pageQuery = graphql`
  query {
    allWordpressWpStudent(
      sort: { fields: title, order: ASC }
      filter: {
        acf: {
          start_year: { eq: "2021" }
          exclude_from_show: { ne: "exclude" }
        }
      }
    ) {
      edges {
        node {
          title
          slug
          acf {
            start_year
            final_major_project {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 350) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
